<template>
  <div class="">
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="960px"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="examRule_head flex">
        <span>房屋交接清单</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="合同编号：" prop="contract_number">
            <el-input
              disabled
              placeholder="请输入"
              v-model="ruleForm.contract_number"
            ></el-input>
          </el-form-item>
          <el-form-item></el-form-item>
          <!-- <el-form-item label="物业地址：" prop="w_address">
            <el-input
              placeholder="请输入"
              v-model="ruleForm.w_address"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="水表读数：">
            <el-input placeholder="请输入" v-model="ruleForm.water"></el-input>
          </el-form-item>
          <el-form-item label="电表读数：">
            <el-input
              placeholder="请输入"
              v-model="ruleForm.electricity"
            ></el-input>
          </el-form-item>
          <el-form-item label="煤气读数：">
            <el-input
              placeholder="请输入"
              v-model="ruleForm.coal_gas"
            ></el-input>
          </el-form-item>
          <el-form-item label="抄表日期：">
            <!-- <el-input placeholder="请输入" v-model="ruleForm.c_time"></el-input> -->
            <el-date-picker
              style="width: 100%"
              v-model="ruleForm.c_time"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="物业已结清至：">
            <el-date-picker
              style="width: 100%"
              v-model="ruleForm.w_time"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="有线电视：">
            <el-select v-model="ruleForm.television" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="网费：">
            <el-select v-model="ruleForm.wang" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="暖气：">
            <el-select v-model="ruleForm.nuanqi" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="已结清至：">
            <el-date-picker
              v-model="ruleForm.nuanqi_time"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item style="width: 100%" label="客厅餐厅：">
            <div class="specification">
              <div class="specification-image flex" style="height: 40px">
                <img
                  @click="addItem('drawingList')"
                  style="width: 24px; height: 24px; cursor: pointer"
                  src="../assets/image/home/add.png"
                  alt=""
                />
              </div>
              <div class="specification-ul">
                <div
                  class="specification-li"
                  v-for="(item, index) in drawingList"
                  :key="index"
                >
                  <img @click="delItem('drawingList',index)" src="../assets/image/home/del.png" alt="" />
                  <div class="flexs">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      名称:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.name"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      规格:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.specifications"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      品牌:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.brand"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      数量:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.number"
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item style="width: 100%" label="书房：">
            <div class="specification">
              <div class="specification-image flex" style="height: 40px">
                <img
                  @click="addItem('studyList')"
                  style="width: 24px; height: 24px; cursor: pointer"
                  src="../assets/image/home/add.png"
                  alt=""
                />
              </div>
              <div class="specification-ul">
                <div
                  class="specification-li"
                  v-for="(item, index) in studyList"
                  :key="index"
                >
                <img @click="delItem('studyList',index)" src="../assets/image/home/del.png" alt="" />
                  <div class="flexs">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      名称:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.name"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      规格:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.specifications"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      品牌:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.brand"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      数量:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.number"
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item style="width: 100%" label="厨房：">
            <div class="specification">
              <div class="specification-image flex" style="height: 40px">
                <img
                  @click="addItem('kitchenList')"
                  style="width: 24px; height: 24px; cursor: pointer"
                  src="../assets/image/home/add.png"
                  alt=""
                />
              </div>
              <div class="specification-ul">
                <div
                  class="specification-li"
                  v-for="(item, index) in kitchenList"
                  :key="index"
                >
                <img @click="delItem('kitchenList',index)" src="../assets/image/home/del.png" alt="" />
                  <div class="flexs">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      名称:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.name"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      规格:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.specifications"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      品牌:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.brand"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      数量:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.number"
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item style="width: 100%" label="主卫：">
            <div class="specification">
              <div class="specification-image flex" style="height: 40px">
                <img
                  @click="addItem('mainBathroom')"
                  style="width: 24px; height: 24px; cursor: pointer"
                  src="../assets/image/home/add.png"
                  alt=""
                />
              </div>
              <div class="specification-ul">
                <div
                  class="specification-li"
                  v-for="(item, index) in mainBathroom"
                  :key="index"
                >
                <img @click="delItem('mainBathroom',index)" src="../assets/image/home/del.png" alt="" />
                  <div class="flexs">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      名称:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.name"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      规格:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.specifications"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      品牌:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.brand"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      数量:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.number"
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item style="width: 100%" label="主卧：">
            <div class="specification">
              <div class="specification-image flex" style="height: 40px">
                <img
                  @click="addItem('masterBedroomList')"
                  style="width: 24px; height: 24px; cursor: pointer"
                  src="../assets/image/home/add.png"
                  alt=""
                />
              </div>
              <div class="specification-ul">
                <div
                  class="specification-li"
                  v-for="(item, index) in masterBedroomList"
                  :key="index"
                >
                <img @click="delItem('masterBedroomList',index)" src="../assets/image/home/del.png" alt="" />
                  <div class="flexs">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      名称:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.name"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      规格:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.specifications"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      品牌:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.brand"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      数量:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.number"
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item style="width: 100%" label="次卫：">
            <div class="specification">
              <div class="specification-image flex" style="height: 40px">
                <img
                  @click="addItem('secondaryList')"
                  style="width: 24px; height: 24px; cursor: pointer"
                  src="../assets/image/home/add.png"
                  alt=""
                />
              </div>
              <div class="specification-ul">
                <div
                  class="specification-li"
                  v-for="(item, index) in secondaryList"
                  :key="index"
                >
                <img @click="delItem('secondaryList',index)" src="../assets/image/home/del.png" alt="" />
                  <div class="flexs">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      名称:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.name"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      规格:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.specifications"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      品牌:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.brand"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      数量:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.number"
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item style="width: 100%" label="客卧：">
            <div class="specification">
              <div class="specification-image flex" style="height: 40px">
                <img
                  @click="addItem('guestBedroomList')"
                  style="width: 24px; height: 24px; cursor: pointer"
                  src="../assets/image/home/add.png"
                  alt=""
                />
              </div>
              <div class="specification-ul">
                <div
                  class="specification-li"
                  v-for="(item, index) in guestBedroomList"
                  :key="index"
                >
                <img @click="delItem('guestBedroomList',index)" src="../assets/image/home/del.png" alt="" />
                  <div class="flexs">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      名称:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.name"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      规格:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.specifications"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      品牌:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.brand"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      数量:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.number"
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item style="width: 100%" label="阳台：">
            <div class="specification">
              <div class="specification-image flex" style="height: 40px">
                <img
                  @click="addItem('balconyList')"
                  style="width: 24px; height: 24px; cursor: pointer"
                  src="../assets/image/home/add.png"
                  alt=""
                />
              </div>
              <div class="specification-ul">
                <div
                  class="specification-li"
                  v-for="(item, index) in balconyList"
                  :key="index"
                >
                <img @click="delItem('balconyList',index)" src="../assets/image/home/del.png" alt="" />
                  <div class="flexs">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      名称:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.name"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      规格:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.specifications"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      品牌:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.brand"
                    ></el-input>
                  </div>
                  <div class="flexs m-t-10">
                    <div
                      class=""
                      style="
                        color: #666666;
                        font-size: 16px;
                        margin-right: 20px;
                      "
                    >
                      数量:
                    </div>
                    <el-input
                      placeholder="请输入"
                      v-model="item.number"
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入"
              v-model="ruleForm.notes"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="出租方：">
            <el-input
              placeholder="请输入"
              v-model="ruleForm.chu_name"
            ></el-input>
          </el-form-item>
          <el-form-item label="承租方：" prop="z_name">
            <el-input placeholder="请输入" v-model="ruleForm.z_name"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="签约日期："  prop="q_time">
            <el-date-picker
              v-model="ruleForm.q_time"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item> -->
          <!-- <el-form-item label="公司电子章：" prop="image">
            <el-upload
              class="avatar-uploader"
              action="https://xin.xinyoujudichan.com/api/common/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="ruleForm.image" :src="ruleForm.image" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item> -->

          <el-form-item
            label-width="0"
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              margin-bottom: 0;
              margin-top: 30px;
            "
          >
            <el-button
              style="width: 480px; height: 56px; border-radius: 28px"
              type="primary"
              @click="submitForm('ruleForm')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        contract_number: "",
        w_address: "", //物业地址
        water: "", //水表读数
        electricity: "", //电表读数
        coal_gas: "", //煤气读数
        c_time: "", //抄表日期
        w_time: "", //物业费已结清时间
        television: "", //有线电视
        wang: "", //网费
        nuanqi: "", //暖气
        nuanqi_time: "", //暖气费时间
        ke: "", //客厅信息
        shufang: "", //书房信息
        chufang: "", //厨房信息
        zhuwei: "", //主卫信息
        zhuwo: "", //主卧信息
        ciwei: "", //次卫信息
        kewo: "", //客卧信息
        yangtai:"",//阳台信息
        notes: "", //备注
        chu_name: "", //出租房姓名
        z_name: "", //承租方姓名
        image: "", //电子章
        q_time: "", //签约日期
        shenhe_id:null
      },
      rules: {
        contract_number: [
          { required: true, message: "请输入合同编号", trigger: "blur" }
        ],
        w_address:[{ required: true, message: "请输入物业地址", trigger: "blur" }],
        water:[{ required: true, message: "请输入水表读数", trigger: "blur" }],
        electricity:[{ required: true, message: "请输入电表读数", trigger: "blur" }],
        coal_gas:[{ required: true, message: "请输入煤气读数", trigger: "blur" }],
        c_time:[{ required: true, message: "请输入抄表日期", trigger: "blur" }],
        television: [
          { required: true, message: "请选择有无有线电视", trigger: "change" },
        ],
        wang: [
          { required: true, message: "请选择有无网费", trigger: "change" },
        ],
        nuanqi: [
          { required: true, message: "请选择有无暖气", trigger: "change" },
        ],
        w_time: [
          {
            type: "string",
            required: true,
            message: "请选择物业已结清至日期",
            trigger: "change",
          },
        ],
        chu_name: [{ required: true, message: "请输入出租方", trigger: "blur" }],
        z_name: [{ required: true, message: "请输入承租方", trigger: "blur" }],
        q_time: [
          {
            type: "string",
            required: true,
            message: "请选择签约日期",
            trigger: "change",
          },
        ],
        image: [{ required: true, message: "请上传公司电子章" }],
      },
      options:[
        {
          value: '有',
          label: '有'
        },
        {
          value: '无',
          label: '无'
        }
      ],
      drawingList:[],//客厅餐厅
      studyList:[],//书房
      kitchenList:[],//厨房
      mainBathroom:[],//主卫
      masterBedroomList:[],//主卧
      secondaryList:[],//次卫
      guestBedroomList:[],//客卧
      balconyList:[],//阳台
    };
  },
  methods: {
    delItem (from,index) {
      this[from].splice(index,1)
    },
    addItem (from) {
      this[from].push({name:'',specifications:'',brand:'',number:''})
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.drawingList.length) this.ruleForm.ke = JSON.stringify(this.drawingList);

          if (this.studyList.length) this.ruleForm.shufang = JSON.stringify(this.studyList);

          if (this.kitchenList.length) this.ruleForm.chufang = JSON.stringify(this.kitchenList);

          if (this.mainBathroom.length) this.ruleForm.zhuwei = JSON.stringify(this.mainBathroom);

          if (this.masterBedroomList.length) this.ruleForm.zhuwo = JSON.stringify(this.masterBedroomList);

          if (this.secondaryList.length) this.ruleForm.ciwei = JSON.stringify(this.secondaryList);

          if (this.guestBedroomList.length) this.ruleForm.kewo = JSON.stringify(this.guestBedroomList);

          if (this.balconyList.length) this.ruleForm.yangtai = JSON.stringify(this.balconyList);

          this.$axios.jiaoJie(this.ruleForm).then((res) => {
            
            this.$emit('success',res.data)
            this.$message.success("上传成功");
            this.dialogVisible = false;
          });
        } else {
          return false;
        }
      });
    },
    close() {
      this.dialogVisible = false;
    },
    open({id,shenhe_id}) {
      this.ruleForm.contract_number = id;
      this.ruleForm.shenhe_id = shenhe_id;
      this.dialogVisible = true;
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.image = res.data.fullurl;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}

.demo-ruleForm {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 20px;
}

/deep/ .el-form-item {
  width: 50%;
}

.specification-ul {
  display: flex;
  flex-wrap: wrap;

  .specification-li {
    margin: 20px 40px 0 0;
    position: relative;
    width: 350px;
    padding: 20px 10px;
    background: #f5f5f5;

    img {
      top: 10px;
      right: 10px;
      width: 15px;
      height: 15px;
      z-index: 9;
      cursor: pointer;
      position: absolute;
    }

    /deep/ .el-input {
      width: 220px;
    }
  }
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
