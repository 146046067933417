<template>
  <div class="signature">
    <el-dialog
      title=""
      :show-close="false"
      @opened="openDiv"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="560px"
    >
      <div class="examRule_head flex">
        <span>上传信息</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="signature_main">
        <canvas ref="canvas" id="canvas" height="200" width="500"></canvas>

        <div class="signature_footer m-t-20 p-l-30 p-r-30">
          <el-button @click="downloadImg">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      canvas: "",
      context: "",
      eraser: "",
      brush: "",
      reSetCanvas: "",
      save: "",
      penDetail: "",
      aColorBtn: "",
      undo: "",
      redo: "",
      range1: "",
      range2: "",
      showOpacity: "",
      closeBtn: "",
      eraserEnabled: false,
      activeBgColor: "#fff",
      ifPop: false,
      lWidth: 2,
      opacity: 1,
      strokeColor: "rgba(0,0,0,1)",
      radius: 5,
      step: -1,
      canvasHistory: [],
      lastPoint: { x: undefined, y: undefined }, // 记录画笔最后一次的位置
      painting: false, // 定义一个变量初始化画笔状态
      type:''
    };
  },
  methods: {
    //关闭
    close() {
      this.dialogVisible = false;
    },
    openDiv() {
      this.canvas = this.$refs.canvas;
      this.context = this.canvas.getContext("2d");
      this.autoSetSize();

      this.setCanvasBg("white");

      this.listenToUser();
    },
    open(j_image) {
      this.type = j_image
      this.dialogVisible = true;
    },
    // * 下面是实现相关效果的函数，可以不用看 */

    autoSetSize() {
      this.canvasSetSize();

      window.onresize = () => {
        this.canvasSetSize();
      };
    },
    canvasSetSize() {
      // 把变化之前的画布内容copy一份，然后重新画到画布上
      let imgData = this.context.getImageData(
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
      this.context.putImageData(imgData, 0, 0);
    },
    // 监听用户鼠标事件
    listenToUser() {
      // eslint-disable-next-line no-constant-condition
      if (document.body.ontouchstart !== undefined) {
        this.canvas.ontouchstart = (e) => {
          this.painting = true;
          let x1 = e.touches[0].clientX;
          let y1 = e.touches[0].clientY;
          if (this.eraserEnabled) {
            //要使用eraser
            this.context.save();
            this.context.globalCompositeOperation = "destination-out";
            this.context.beginPath();
            this.radius = this.lWidth / 2 > 5 ? this.lWidth / 2 : 5;
            this.context.arc(x1, y1, this.radius, 0, 2 * Math.PI);
            this.context.clip();
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.context.restore();
            this.lastPoint = { x: x1, y: y1 };
          } else {
            this.lastPoint = { x: x1, y: y1 };
          }
        };
        this.canvas.ontouchmove = (e) => {
          let x1 = this.lastPoint["x"];
          let y1 = this.lastPoint["y"];
          let x2 = e.touches[0].clientX;
          let y2 = e.touches[0].clientY;
          if (!this.painting) {
            return;
          }
          if (this.eraserEnabled) {
            this.moveHandler(x1, y1, x2, y2);
            //记录最后坐标
            this.lastPoint["x"] = x2;
            this.lastPoint["y"] = y2;
          } else {
            let newPoint = { x: x2, y: y2 };
            this.drawLine(
              this.lastPoint.x,
              this.lastPoint.y,
              newPoint.x,
              newPoint.y
            );
            this.lastPoint = newPoint;
          }
        };

        this.canvas.ontouchend = () => {
          this.painting = false;
          this.canvasDraw();
        };
      } else {
        // 鼠标按下事件
        this.canvas.onmousedown = (e) => {
          this.painting = true;
          // let x1 = e.clientX;
          // let y1 = e.clientY;

          let x1 = e.layerX;
          let y1 = e.layerY;

          if (this.eraserEnabled) {
            //要使用eraser
            //鼠标第一次点下的时候擦除一个圆形区域，同时记录第一个坐标点
            this.context.save();
            this.context.globalCompositeOperation = "destination-out";
            this.context.beginPath();
            this.radius = this.lWidth / 2 > 5 ? this.lWidth / 2 : 5;
            this.context.arc(x1, y1, this.radius, 0, 2 * Math.PI);
            this.context.clip();
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.context.restore();
            this.lastPoint = { x: x1, y: y1 };
          } else {
            this.lastPoint = { x: x1, y: y1 };
          }
        };

        // 鼠标移动事件
        this.canvas.onmousemove = (e) => {
          let x1 = this.lastPoint["x"];
          let y1 = this.lastPoint["y"];
          // let x2 = e.clientX;
          // let y2 = e.clientY;
          let x2 = e.layerX;
          let y2 = e.layerY;
          if (!this.painting) {
            return;
          }
          if (this.eraserEnabled) {
            this.moveHandler(x1, y1, x2, y2);
            //记录最后坐标
            this.lastPoint["x"] = x2;
            this.lastPoint["y"] = y2;
          } else {
            let newPoint = { x: x2, y: y2 };
            this.drawLine(
              this.lastPoint.x,
              this.lastPoint.y,
              newPoint.x,
              newPoint.y
            );
            this.lastPoint = newPoint;
          }
        };

        // 鼠标松开事件
        this.canvas.onmouseup = () => {
          this.painting = false;
          this.canvasDraw();
        };
      }
    },

    //
    moveHandler(x1, y1, x2, y2) {
      //获取两个点之间的剪辑区域四个端点
      var asin = this.radius * Math.sin(Math.atan((y2 - y1) / (x2 - x1)));
      var acos = this.radius * Math.cos(Math.atan((y2 - y1) / (x2 - x1)));
      var x3 = x1 + asin;
      var y3 = y1 - acos;
      var x4 = x1 - asin;
      var y4 = y1 + acos;
      var x5 = x2 + asin;
      var y5 = y2 - acos;
      var x6 = x2 - asin;
      var y6 = y2 + acos;
      //保证线条的连贯，所以在矩形一端画圆
      this.context.save();
      this.context.beginPath();
      this.context.globalCompositeOperation = "destination-out";
      this.radius = this.lWidth / 2 > 5 ? this.lWidth / 2 : 5;
      this.context.arc(x2, y2, this.radius, 0, 2 * Math.PI);
      this.context.clip();
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.context.restore();
      //清除矩形剪辑区域里的像素
      this.context.save();
      this.context.beginPath();
      this.context.globalCompositeOperation = "destination-out";
      this.context.moveTo(x3, y3);
      this.context.lineTo(x5, y5);
      this.context.lineTo(x6, y6);
      this.context.lineTo(x4, y4);
      this.context.closePath();
      this.context.clip();
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.context.restore();
    },

    // 画线函数
    drawLine(x1, y1, x2, y2) {
      this.context.beginPath();
      this.context.lineWidth = this.lWidth;
      // context.strokeStyle = strokeColor;
      // context.globalAlpha = opacity;
      // 设置线条末端样式。
      this.context.lineCap = "round";
      // 设定线条与线条间接合处的样式
      this.context.lineJoin = "round";
      this.context.moveTo(x1, y1);
      this.context.lineTo(x2, y2);
      this.context.stroke();
      this.context.closePath();
    },

    // 点击橡皮檫
    // eraser.onclick = function(){
    //     eraserEnabled = true;
    //     eraser.classList.add('active');
    //     brush.classList.remove('active');
    // }
    // // 点击画笔
    // brush.onclick = function(){
    //     eraserEnabled = false;
    //     brush.classList.add('active');
    //     eraser.classList.remove('active');
    //     if(!ifPop){
    //         // 弹出框
    //         penDetail.classList.add('active');
    //     }else{
    //         penDetail.classList.remove('active');
    //     }
    //     ifPop = !ifPop;
    // }

    // 实现清屏
    // reSetCanvas.onclick = function(){
    //     context.clearRect(0,0,canvas.width,canvas.height);
    //     setCanvasBg('white');
    //     canvasHistory = [];
    //     undo.classList.remove('active');
    //     redo.classList.remove('active');
    // }

    // 重新设置canvas背景颜色
    setCanvasBg(color) {
      this.context.fillStyle = color;
      this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    },

    // 下载图片
    downloadImg() {
      let imgUrl = this.canvas.toDataURL("image/png");
      let saveA = document.createElement("a");
      document.body.appendChild(saveA);
      saveA.href = imgUrl;
      this.$axios.uploadFile(this.dataURLtoFile(imgUrl,(new Date).getTime() + 'bg.png')).then((res) => {
        this.dialogVisible = false
        this.$emit('succeed',{type:this.type,image:res.fullurl})
      });
    },
    
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
    },

    // 实现了切换背景颜色
    /* for (let i = 0; i < bgcolorBtn.length; i++) {
    bgcolorBtn[i].onclick = function (e) {
        e.stopPropagation();
        for (let i = 0; i < bgcolorBtn.length; i++) {
            bgcolorBtn[i].classList.remove("active");
            this.classList.add("active");
            activeBgColor = this.style.backgroundColor;
            setCanvasBg(activeBgColor);
        }

    }
}
document.onclick = function(){
    bgGroup.classList.remove('active');
}

selectBg.onclick = function(e){
    bgGroup.classList.add('active');
    e.stopPropagation();
} */

    // 实现改变画笔粗细的功能 1-20 放大的倍数 1 10 实际大小呢？ 2-20

    // range1.onchange = function(){
    //     thickness.style.transform = 'scale('+ (parseInt(range1.value)) +')';
    //     lWidth = parseInt(range1.value*2);
    // }

    // range2.onchange = function(){
    //     opacity = 1 - parseInt(this.value)/10;
    //     if(opacity !== 0){
    //         showOpacity.style.opacity = opacity;
    //     }
    // }

    // 改变画笔颜色
    // getColor();

    getColor() {
      for (let i = 0; i < this.aColorBtn.length; i++) {
        this.aColorBtn[i].onclick = function () {
          // e.stopPropagation();
          for (let i = 0; i < this.aColorBtn.length; i++) {
            this.aColorBtn[i].classList.remove("active");
            this.classList.add("active");
            this.activeColor = this.style.backgroundColor;
            this.context.fillStyle = this.activeColor;
            this.context.strokeStyle = this.activeColor;
          }
          this.penDetail.classList.remove("active");
          this.ifPop = false;
        };
      }
    },

    // 实现撤销和重做的功能
    // let canvasHistory = [];
    // let step = -1;

    // 绘制方法
    canvasDraw() {
      this.step++;
      if (this.step < this.canvasHistory.length) {
        this.canvasHistory.length = this.step; // 截断数组
      }
      // 添加新的绘制到历史记录
      this.canvasHistory.push(this.canvas.toDataURL());
    },

    // 撤销方法
    canvasUndo() {
      if (this.step > 0) {
        this.step--;
        let canvasPic = new Image();
        canvasPic.src = this.canvasHistory[this.step];
        canvasPic.onload = function () {
          this.context.drawImage(canvasPic, 0, 0);
        };
        this.undo.classList.add("active");
        this.redo.classList.add("active");
      } else {
        this.undo.classList.remove("active");
        alert("不能再继续撤销了");
      }
    },
    // 重做方法
    canvasRedo() {
      if (this.step < this.canvasHistory.length - 1) {
        this.step++;
        let canvasPic = new Image();
        canvasPic.src = this.canvasHistory[this.step];
        canvasPic.onload = function () {
          this.context.drawImage(canvasPic, 0, 0);
        };
        // redo.classList.add('active');
      } else {
        this.redo.classList.remove("active");
        alert("已经是最新的记录了");
      }
    },
  },
  mounted() {
    // let eraser = document.getElementById('eraser');
    // let brush = document.getElementById('brush');
    // let reSetCanvas = document.getElementById("clear");
    // let save = document.getElementById("save");
    /* let selectBg = document.querySelector('.bg-btn');
    let bgGroup = document.querySelector('.color-group');
    let bgcolorBtn = document.querySelectorAll('.bgcolor-item'); */
    // let penDetail = document.getElementById("penDetail");
    // let aColorBtn = document.getElementsByClassName("color-item");
    // let redo = document.getElementById("redo");
    // let range1 = document.getElementById('range1');
    // let range2 = document.getElementById('range2');
    // let showOpacity = document.querySelector('.showOpacity');
    // let closeBtn = document.querySelectorAll('.closeBtn');
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0;
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
  /deep/ .el-select {
    width: 100%;
  }
  /deep/ .el-date-editor.el-input {
    width: 100%;
  }
}
.signature_main {
  padding: 30px;
}
.signature_footer {
  /deep/ .el-button {
    width: 100%;
    // height: 56px;
    color: #fff;
    border-radius: 8px;
    background: #3273f6;
  }
}
canvas {
  width: 500px;
  height: 200px;
  display: block;
  border: 1px solid #000;
}
</style>
