<template>
  <div class="">
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="960px"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="examRule_head flex">
        <span>风险告知书</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="合同编号：" prop="contract_number">
            <el-input
              disabled
              placeholder="请输入"
              v-model="ruleForm.contract_number"
            ></el-input>
          </el-form-item>
          <el-form-item></el-form-item>
          <!-- <el-form-item label="承租方客户：" prop="z_name">
            <el-input
              v-model="ruleForm.z_name"
              placeholder="请输入承租方客户姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="签字日期：" prop="z_time">
            <el-date-picker
              v-model="ruleForm.z_time"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item> -->

          <!-- <el-form-item label="见证方：" prop="j_name">
            <el-select v-model="ruleForm.j_name" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="合同签字日期：" prop="q_time">
            <el-date-picker
              v-model="ruleForm.q_time"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item> -->
          <!-- <el-form-item label="公司电子章：" prop="image">
           
            <el-upload
              class="avatar-uploader"
              action="https://xin.xinyoujudichan.com/api/common/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img
                v-if="ruleForm.image"
                :src="ruleForm.image"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item> -->
          <el-form-item></el-form-item>
          <el-form-item
            label-width="0"
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              margin-bottom: 0;
              margin-top: 30px;
            "
          >
            <el-button
              style="width: 480px; height: 56px; border-radius: 28px"
              type="primary"
              @click="submitForm('ruleForm')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        contract_number: "", //合同编号
        z_name: "", //客户姓名
        z_time: "", //签字日期
        j_name: "", //见证方公司名称
        image: "", //电子章
        q_time: "", //签约日期
        shenhe_id:null
      },
      options: [],
      rules: {
        contract_number: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
        z_time: [
          {
            type: "string",
            required: true,
            message: "请选择签字日期",
            trigger: "change",
          },
        ],
        q_time: [
          {
            type: "string",
            required: true,
            message: "请选择签约日期",
            trigger: "change",
          },
        ],
        j_name: [
          {
            required: true,
            message: "请选择见证方公司名称",
            trigger: "change",
          },
        ],
        z_name: [
          { required: true, message: "请输入承租方客户姓名", trigger: "blur" },
        ],
        image: [{ required: true, message: "请上传公司电子章" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.fengXian(this.ruleForm).then((res) => {
            
            this.$emit('success',res.data)
            this.$message.success("上传成功");
            this.dialogVisible = false;
          });
        } else {
          return false;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.image = res.data.fullurl;
    },
    beforeAvatarUpload() {},
    open({id,shenhe_id}) {
      this.dialogVisible = true;
      this.ruleForm.contract_number = id;
      this.ruleForm.shenhe_id = shenhe_id;
    },
    close() {
      this.dialogVisible = false;
    },
    getfangZhang() {
      this.$axios.fangZhang().then((res) => {
        this.options = res.data;
      });
    },
  },
  created() {
    this.getfangZhang();
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}

.demo-ruleForm {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 20px;
}

/deep/ .el-form-item {
  width: 50%;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
