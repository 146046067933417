// 借钥匙
<template>
  <div class="examRule">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="960px"
    >
      <div class="examRule_head flex">
        <span>提交信息</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="examRule_main p-t-30 p-r-20 p-b-30 p-l-20">
        <div class="borrow-form">
          <el-form
            ref="from"
            :rules="rules"
            class="form"
            :model="from"
            label-width="140px"
          >
            <el-form-item label="合同编号：" prop="bianhao">
              <el-input
                disabled
                v-model="from.bianhao"
                placeholder="请输入合同编号"
              ></el-input>
            </el-form-item>
            <el-form-item> </el-form-item>
            <el-form-item label="出租方房东：" prop="chu_name">
              <el-input
                v-model="from.chu_name"
                placeholder="请输入房东的姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号:" prop="chu_id">
              <el-input
                v-model="from.chu_id"
                placeholder="请输入房东的身份证号"
              ></el-input>
            </el-form-item>
            <el-form-item label="承租方客户:" prop="cheng_name">
              <!-- <el-input
                v-model="from.cheng_name"
                placeholder="请输入承租方客户姓名"
              ></el-input> -->
              <el-select
                @change="changeChengName"
                remote
                :remote-method="remoteMethod"
                v-model="from.cheng_name"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="身份证号:" prop="cheng_id">
              <el-input
                v-model="from.cheng_id"
                placeholder="请输入客户的身份证号"
              ></el-input>
            </el-form-item>
            <el-form-item label="见证方:" prop="jian_name">
              <el-select
                v-model="from.jian_name"
                placeholder="请选择见证方公司名称"
              >
                <el-option
                  v-for="item in witnessList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="房屋位置:" prop="fang_address">
              <el-input
                v-model="from.fang_address"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="租赁用途:" prop="fang_y">
              <el-select v-model="from.fang_y" placeholder="请选择租赁用途">
                <el-option
                  v-for="item in useList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="建筑面积：" prop="mianji">
              <el-input v-model="from.mianji" placeholder="请输入"
                ><el-button slot="append">平方米</el-button></el-input
              >
            </el-form-item> -->
            <el-form-item label="租赁期限共:" prop="times">
              <el-select v-model="from.times" placeholder="请选择租赁期限">
                <el-option
                  v-for="item in tenancyList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="合同起始日期：" prop="start_time">
              <el-date-picker
                @change="changeTimes($event, 'start_time')"
                v-model="from.start_time"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="合同结束日期：" prop="end_time">
              <el-date-picker
                @change="changeTimes($event, 'end_time')"
                v-model="from.end_time"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="房租租金：" prop="money">
              <div class="flex">
                <el-input
                  v-model="from.money"
                  placeholder="(大写)"
                  ><el-button slot="append">元/月</el-button></el-input
                >
                <div class="m-l-10">
                  <el-input
                    v-model="from.moneys"
                    placeholder="(小写)"
                    ><el-button slot="append">元/月</el-button></el-input
                  >
                </div>
              </div>
            </el-form-item>
            <el-form-item label="相关税费承担方：" prop="s_price">
              <el-select v-model="from.s_price" placeholder="请选择相关税费承担方">
                <el-option
                  v-for="item in correlationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="房屋定金：" prop="d_price">
              <el-input
                v-model="from.d_price"
                placeholder="请输入房屋定金(大写)"
                ><el-button slot="append">元/月</el-button></el-input
              >
            </el-form-item>
            <el-form-item label="租金支付方式：" prop="fu">
              
              <el-select v-model="from.fu" placeholder="租金支付方式">
                <el-option
                  v-for="item in paymentList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="支付时间:" prop="f_time">
              <el-date-picker
                v-model="from.f_time"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择支付时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="甲方服务费：" prop="j_prices_d">
              <div class="flex">
                <el-input
                  v-model="from.j_prices_d"
                  placeholder="(大写)"
                  ><el-button slot="append">元</el-button></el-input
                >
                <div class="m-l-10">
                  <el-input v-model="from.j_prices" placeholder="(小写)"
                    ><template slot="prepend">￥</template></el-input
                  >
                </div>
              </div>
            </el-form-item>
            <el-form-item label="乙方服务费：" prop="y_prices_y">
              <div class="flex">
                <el-input
                  v-model="from.y_prices_y"
                  placeholder="(大写)"
                  ><el-button slot="append">元</el-button></el-input
                >
                <div class="m-l-10">
                  <el-input v-model="from.y_prices" placeholder="(小写)"
                    ><template slot="prepend">￥</template></el-input
                  >
                </div>
              </div>
            </el-form-item>
            <el-form-item label="乙方承担租期费用：" prop="tage">
              <el-input
                v-model="from.tage"
                placeholder="请输入乙方租期内承担实际使用费用"
              ></el-input>
            </el-form-item>
            <el-form-item label="房屋押金：" prop="ya_prices">
              <el-input
                v-model="from.ya_prices"
                placeholder="请输入房屋押金(大写)"
                ><el-button slot="append">元</el-button></el-input
              >
            </el-form-item>
            <el-form-item label="特别约定:" prop="yueding">
              <el-input
                type="textarea"
                :rows="5"
                placeholder="请输入"
                v-model="from.yueding"
              >
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="出租方签名：" prop="j_image">
              <div class="avatar-uploader" v-if="from.j_image">
                <img class="avatar" :src="from.j_image" alt="" />
              </div>
              <el-button
                v-else
                @click="$refs.signature.open('j_image')"
                size="small"
                type="primary"
                >开始签名</el-button
              >
            </el-form-item>
            <el-form-item label="出租方电话：" prop="j_phone">
              <el-input
                v-model="from.j_phone"
                placeholder="请输入出租方电话"
              ></el-input>
            </el-form-item>
            <el-form-item label="承租人签名：" prop="y_image">
              <div class="avatar-uploader" v-if="from.y_image">
                <img class="avatar" :src="from.y_image" alt="" />
              </div>
              <el-button
                v-else
                @click="$refs.signature.open('y_image')"
                size="small"
                type="primary"
                >开始签名</el-button
              >
            </el-form-item> -->
            <!-- <el-form-item label="承租人电话：" prop="y_phone">
              <el-input
                v-model="from.y_phone"
                placeholder="请输入承租人电话"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="合同签约日期：" prop="qian_time">
              <el-date-picker
                v-model="from.qian_time"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          
          <div class="interview_main_footer center">
            <el-button @click="submit('from')">提交</el-button>
          </div>
          <div class="annex" v-if="from.shenhe_id">
            <div class="" style="color: #3273F6;font-size: 18px;">合同附件(确保合同内容以及附件内容全部填写完毕后，请点击该弹窗页面右上角x号)</div>
            <div class="annex-list">
              <div @click="changeAnnex(item,index)" class="annex-li" v-for="(item,index) in annexList" :key="index">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <signature ref="signature" @succeed="changeImage"></signature>
    <AnnexListPop ref="annex" @success="changeFengxian"></AnnexListPop>
    <CommissionPop ref="commission" @success="changeCommission"></CommissionPop>
    <InventoryPop ref="inventory" @success="changeInventory"></InventoryPop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Signature from "@/components/Signature.vue";
import AnnexListPop from '@/components/AnnexListPop.vue';
import CommissionPop from '@/components/CommissionPop.vue';
import InventoryPop from '@/components/InventoryPop.vue';
export default {
  components: { Signature,AnnexListPop,CommissionPop, InventoryPop },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo"]),
    isSubmit () {
      return this.from.fengxian_id && this.from.jin_chu_id && this.from.fang_jiao_id && this.from.jin_zu_id
    }
  },
  watch:{
    isSubmit (newVal) {
      if (newVal) {
        this.dialogVisible = false
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      content: "", //规则
      from: {
        fang_id: "", //房源id
        bianhao: "", //合同编号
        chu_name: "", //出租房东
        chu_id: "", //出租房东身份证
        cheng_name: "", //承租人
        cheng_id: "", //承租人身份证
        jian_name: "", //见证方
        fang_address: "", //房屋位置
        fang_y: "", //租赁用途
        mianji: "", //建筑面积
        times: "", //租赁时间（月）
        start_time: "", //开始时间
        end_time: "", //结束时间
        year: "", //开始时间-年
        month: "", //开始时间-月
        day: "", //开始时间-日
        years: "", //结束时间-年
        months: "", //结束时间-月
        days: "", //结束时间-日
        money: "", //租金大写
        moneys: "", //租金小写
        s_price: "", //税费
        d_price: "", //定金-大写
        fu: "", //支付方式
        f_time: "", //支付时间
        j_prices: "", //甲方服务费
        j_prices_d: "", //甲方服务费-大写
        y_prices: "", //乙方服务费
        y_prices_y: "", //乙方服务费-大写
        tage: "", //说明
        ya_prices: "", //押金
        yueding: "", //特别约定
        j_image: "", //出租方签名
        j_phone: "", //出租方电话
        y_image: "", //承租人签名
        y_phone: "", //承租人电话
        jian_image: "", //见证方电子章
        qian_time: "", //签约日期
        fengxian_id:"",//风险告知书id
        jin_chu_id:"",//佣金确认书房东id
        fang_jiao_id:"",//房屋交接清单id
        jin_zu_id:"",//佣金确认书租客id
        shenhe_id:null,
      },
      options: [],
      witnessList: [], //见证方列表
      rules: {
        start_time: [
          {
            type: "string",
            required: true,
            message: "请选择合同起始日期",
            trigger: "change",
          },
        ],
        end_time: [
          {
            type: "string",
            required: true,
            message: "请选择合同结束日期",
            trigger: "change",
          },
        ],
        qian_time: [
          {
            type: "string",
            required: true,
            message: "请选择合同签约日期",
            trigger: "change",
          },
        ],
        price: [
          { required: true, message: "请输入成交价格(元)", trigger: "blur" },
        ],
        text: [{ required: true, message: "请输入内容", trigger: "blur" }],
        chu_name: [
          { required: true, message: "请输入房东的姓名", trigger: "blur" },
        ],
        chu_id: [
          { required: true, message: "请输入房东的身份证号", trigger: "blur" },
        ],
        cheng_name: [
          { required: true, message: "请输入承租方客户姓名", trigger: "blur" },
        ],
        cheng_id: [
          { required: true, message: "请输入客户的身份证号", trigger: "blur" },
        ],
        jian_name: [
          {
            required: true,
            message: "请选择见证方公司名称",
            trigger: "change",
          },
        ],
        fang_address: [
          { required: true, message: "请输入房屋位置", trigger: "blur" },
        ],
        fang_y: [
          { required: true, message: "请输入租赁用途", trigger: "blur" },
        ],
        mianji: [
          { required: true, message: "请输入建筑面积", trigger: "blur" },
        ],
        times: [{ required: true, message: "请输入租赁期限", trigger: "blur" }],
        money: [{ required: true, message: "请输入房租租金", trigger: "blur" }],
        s_price: [
          {
            required: true,
            message: "请输入相关税费承担方姓名",
            trigger: "blur",
          },
        ],
        d_price: [
          { required: true, message: "请输入房屋定金(大写)", trigger: "blur" },
        ],
        fu: [
          { required: true, message: "请输入租金支付方式", trigger: "blur" },
        ],
        f_time: [
          { required: true, message: "请输入支付时间", trigger: "blur" },
        ],
        j_prices_d: [
          {
            required: true,
            message: "请输入甲方服务费用(大写)",
            trigger: "blur",
          },
        ],
        y_prices_y: [
          {
            required: true,
            message: "请输入乙方服务费用(大写)",
            trigger: "blur",
          },
        ],
        tage: [
          {
            required: true,
            message: "请输入乙方租期内承担实际使用费用",
            trigger: "blur",
          },
        ],
        ya_prices: [
          { required: true, message: "请输入房屋押金(大写)", trigger: "blur" },
        ],
        yueding: [
          { required: true, message: "请输入特别约定", trigger: "blur" },
        ],
        j_image: [{ required: true, message: "请上传出租方签名" }],
        j_phone: [
          { required: true, message: "请输入出租方电话", trigger: "blur" },
        ],
        y_image: [{ required: true, message: "请上传承租人签名" }],
        y_phone: [
          { required: true, message: "请输入承租人电话", trigger: "blur" },
        ],
      },
      list: [], //
      useList: [
        {
          value: "居住",
          label: "居住",
        },
        {
          value: "办公",
          label: "办公",
        },
      ], //房屋用途
      paymentList: [
        {
          value: "一个月一付",
          label: "一个月一付",
        },
        {
          value: "两个月一付",
          label: "两个月一付",
        },
        {
          value: "三个月一付",
          label: "三个月一付",
        },
        {
          value: "四个月一付",
          label: "四个月一付",
        },
        {
          value: "五个月一付",
          label: "五个月一付",
        },
        {
          value: "六个月一付",
          label: "六个月一付",
        },
        {
          value: "七个月一付",
          label: "七个月一付",
        },
        {
          value: "八个月一付",
          label: "八个月一付",
        },
        {
          value: "九个月一付",
          label: "九个月一付",
        },{
          value: "十个月一付",
          label: "十个月一付",
        },
        {
          value: "十一个月一付",
          label: "十一个月一付",
        },
        {
          value: "一年一付",
          label: "一年一付",
        },
      ],
      correlationList:[
      {
          value: "甲方",
          label: "甲方",
        },
        {
          value: "乙方",
          label: "乙方",
        },
      ],
      tenancyList:[],//租期
      annexList:[
        {
          name:'风险告知书',
          type:'annex'
        },
        {
          name:'房屋交接清单',
          type:'inventory'
        },
        {
          name:'佣金确认书房东',
          type:'commission'
        },
        {
          name:'佣金确认书租客',
          type:'commission'
        }
      ]
    };
  },
  methods: {
    changeCommission (data) {
      this.from[data.type == 1 ? 'jin_chu_id' : 'jin_zu_id'] = data.id;
    },
    changeInventory (id) {
      this.from.fang_jiao_id = id;
    },
    changeFengxian (id) {
      this.from.fengxian_id = id;
    },
    changeAnnex (item,index) {
      
      this.$refs[item.type].open({id:this.from.bianhao,type:index == 2 ? 1 : 2,shenhe_id:this.from.shenhe_id})
    },
    changeChengName(e) {
      this.list.forEach((item) => {
        if (item.name == e) {
          this.from.y_phone = item.mobile.split(",")[0];
        }
      });
    },
    remoteMethod(e) {
      this.getList(e);
    },
    changeImage(e) {
      this.from[e.type] = e.image;
    },
    changeTimes(e, type) {
      this.from[type == "start_time" ? "year" : "years"] = e
        ? e.split("-")[0]
        : "";
      this.from[type == "start_time" ? "month" : "months"] = e
        ? e.split("-")[1]
        : "";
      this.from[type == "start_time" ? "day" : "days"] = e
        ? e.split("-")[2]
        : "";
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.houseContract(this.from).then((res) => {
            // this.dialogVisible = false;
            this.$message({
              message: '提交成功，请上传附件',
              type: "success",
            });
            this.from.shenhe_id = res.data
          });
        } else {
          return false;
        }
      });
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(number, id) {
      this.from.bianhao = number;
      this.from.fang_id = id;
      this.dialogVisible = true;
      this.getList("");
      this.getfangZhang();
      let tenancyList = []
      for (let index = 0; index < 60; index++) {
        tenancyList.push({
          value: `${index + 1}个月`,
          label: `${index + 1}个月`
        })
        
      }
      this.tenancyList = tenancyList
      this.$axios.houseZuDetail({id}).then(res=>{
        this.from.fang_address = res.data.fang_address
        this.from.chu_name = res.data.yezhu_name
      })
    },
    getfangZhang() {
      this.$axios.fangZhang().then((res) => {
        this.witnessList = res.data;
      });
    },
    getList(cheng_name) {
      this.$axios
        .clientJ({
          broker_user_id: this.userInfo.id,
          search: cheng_name,
        })
        .then((res) => {
          this.list = res.data;
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-select {
  width: 100%;
}
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
  /deep/ .el-select {
    width: 100%;
  }
  /deep/ .el-date-editor.el-input {
    width: 100%;
  }
}
/deep/ .el-input {
  width: 100%;
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}
/deep/ .el-form {
  display: flex;
  flex-wrap: wrap;
}
/deep/ .el-form-item {
  width: 50%;
}
.interview_main_footer {
  padding: 30px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}

.avatar-uploader {
  width: 180px;
  height: 180px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.annex-list {
  display: flex;
  align-items: center;
  .annex-li {
    width: 120px;
    height: 30px;
    cursor: pointer;
    color: #3273F6;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    margin:20px 10px 0 0;
    border: 1px solid #0253F7;
  }
}
</style>
