<template>
  <div class="">
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="960px"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="examRule_head flex">
        <span>佣金确认书</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="合同编号：" prop="contract_number">
            <el-input
              disabled
              placeholder="请输入"
              v-model="ruleForm.contract_number"
            ></el-input>
          </el-form-item>
          <el-form-item></el-form-item>
          <!-- <el-form-item :label="`${ruleForm.type == 1 ? '房东姓名' : '租客姓名'}：`" prop="c_name">
            <el-input
              placeholder="请输入"
              v-model="ruleForm.c_name"
            ></el-input>
          </el-form-item>
          <el-form-item label="成交物业：" prop="wuye">
            <el-input placeholder="请输入" v-model="ruleForm.wuye"></el-input>
          </el-form-item> -->
          <el-form-item label="交易类型：">
            <el-select v-model="ruleForm.yi_type" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="成交金额：">
            <el-input placeholder="请输入" v-model="ruleForm.price"></el-input>
          </el-form-item>
          <el-form-item label="佣金总金额：">
            <div class="flex">
              <div class="flex-1 m-r-10">
              <el-input placeholder="请输入(小写)" v-model="ruleForm.z_prices"></el-input>
            </div>
            <div class="">
              <el-input placeholder="请输入(大写)" v-model="ruleForm.z_price"></el-input>
            </div>
            </div>
            
            
          </el-form-item> -->
          <!-- <el-form-item label="支付日期：">
            <el-date-picker
              v-model="ruleForm.pay_time"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item> -->
          <!-- <el-form-item label="支付日期：">
            <el-input placeholder="请输入" v-model="ruleForm.name"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="见证方：" prop="j_name">
            <el-select v-model="ruleForm.j_name" placeholder="请选择">
              <el-option
                v-for="item in witnessList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="补充条款：">
            <el-input placeholder="请输入" v-model="ruleForm.notes"></el-input>
          </el-form-item>
          <!-- <el-form-item
            :label="`${ruleForm.type == 1 ? '房东' : '租客'}：`"
            prop="name"
          >
            <el-input placeholder="请输入" v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="联系方式：" prop="mobile" v-if="ruleForm.type == 2">
            <el-input placeholder="请输入" v-model="ruleForm.mobile"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="签约日期：" prop="q_time">
            <el-input placeholder="请输入" v-model="ruleForm.q_time"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="经纪人电话：">
            <el-input
              placeholder="请输入"
              v-model="ruleForm.ji_mpbile"
            ></el-input>
          </el-form-item> -->

          <!-- <el-form-item label="签约日期：" prop="q_time">
            <el-date-picker
              v-model="ruleForm.q_time"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item> -->

          <!-- <el-form-item label="公司电子章：" prop="image">
            <el-upload
              class="avatar-uploader"
              action="https://xin.xinyoujudichan.com/api/common/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="ruleForm.image" :src="ruleForm.image" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item> -->
          <el-form-item></el-form-item>
          <el-form-item
            label-width="0"
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              margin-bottom: 0;
              margin-top: 30px;
            "
          >
            <el-button
              style="width: 480px; height: 56px; border-radius: 28px"
              type="primary"
              @click="submitForm('ruleForm')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    var validateName = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(`请输入${this.ruleForm.type == 1 ? "房东" : "租客"}`)
        );
      } else {
        callback();
      }
    };
    var validatePrice = (rule,value,callback) =>{
      if (!this.ruleForm.z_price && !this.ruleForm.z_prices) callback(new Error('请输入佣金总金额'));
      if (!this.ruleForm.z_price) callback(new Error('请输入服务费用(大写)'));
      if (!this.ruleForm.z_prices) callback(new Error('请输入服务费用(小写)'));
      callback();
    }
    return {
      dialogVisible: false,
      ruleForm: {
        contract_number: "", //合同编号
        type: 1, //类型：1=房东2=租客
        c_name: "", //房东姓名
        wuye: "", //物业
        yi_type: "", //交易类型
        price: "", //成交金额
        z_price: "", //佣金总金额（大写）
        z_prices: "", //佣金总金额（小写）
        pay_time: "", //支付时间
        j_name: "", //见证方公司名称
        notes: "", //补充条款
        name: "", //房东/客户姓名
        mobile: "", //电话
        q_time: "", //签约日期
        image: "", //电子章
        ji_mpbile: "", //经纪人电话
        shenhe_id:null
      },
      options: [
        {
          value: "出租",
          label: "出租",
        },
        {
          value: "求租",
          label: "求租",
        },
        {
          value: "其它",
          label: "其它",
        },
      ],
      rules: {
        name: [
          //
          { required: true, validator: validateName, trigger: "blur" },
        ],
        c_name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        wuye: [{ required: true, message: "请输入成交物业", trigger: "blur" }],
        yi_type: [
          { required: true, message: "请选择交易类型", trigger: "change" },
        ],
        j_name: [
          { required: true, message: "请选择见证方", trigger: "change" },
        ],
        price: [{ required: true, message: "请输入成交金额", trigger: "blur" }],
        z_price: [
          { required: true,validator: validatePrice },
        ],
        pay_time: [
          {
            type: "string",
            required: true,
            message: "请选择支付日期",
            trigger: "change",
          },
        ],
        mobile: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
        ji_mpbile: [{ required: true, message: "请输入经纪人联系方式", trigger: "blur" }],
        q_time: [
          {
            type: "string",
            required: true,
            message: "请选择签约日期",
            trigger: "change",
          },
        ],
        image: [{ required: true, message: "请上传公司电子章" }],
      },
      imageUrl: "",
      witnessList:[],//见证方列表
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.yongJinS(this.ruleForm).then((res) => {
            this.$emit("success", {id:res.data,type:this.ruleForm.type});
            this.$message.success("上传成功");
            for (const key in this.ruleForm) {
              this.ruleForm[key] = "";
            }
            this.dialogVisible = false;
          });
        } else {
          return false;
        }
      });
    },
    close() {
      this.dialogVisible = false;
    },
    open({ id, type ,shenhe_id}) {
      this.ruleForm.type = type;
      this.ruleForm.contract_number = id;
      this.ruleForm.shenhe_id = shenhe_id;
      this.dialogVisible = true;
    },
    getfangZhang() {
      this.$axios.fangZhang().then((res) => {
        this.witnessList = res.data;
      });
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.image = res.data.fullurl;
    },
  },
  created() {
    this.getfangZhang();
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}

.demo-ruleForm {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 20px;
}

/deep/ .el-form-item {
  width: 50%;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
